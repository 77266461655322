import { useState, useEffect, useRef } from 'react'

const MAX_ZOOM = 2.0
const MIN_ZOOM = 1.0

export function useInteraction<T extends HTMLElement>() {
  const interactionRef = useRef<T>(null)
  const [{ dx, dy, scale }, setOffset] = useState({ dx: 0, dy: 0, scale: 1 })
  const [isDragging, setIsDragging] = useState(false)

  // TODO: restrict dragging and scrolling image out of the view

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault()
    if (scale <= 1) return

    const startX = e.pageX - dx
    const startY = e.pageY - dy

    const handleMouseMove = (e: MouseEvent) => {
      const newDx = e.pageX - startX
      const newDy = e.pageY - startY

      setIsDragging(true)
      setOffset({ dx: newDx, dy: newDy, scale })
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener(
      'mouseup',
      () => {
        setIsDragging(false)
        window.removeEventListener('mousemove', handleMouseMove)
      },
      { once: true }
    )
  }

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault()

    const isPinch = Math.abs(e.deltaY) < 50

    if (isPinch || e.ctrlKey) {
      const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1 // Adjust as needed
      const newScale = scale * scaleFactor
      if (newScale < MIN_ZOOM || newScale > MAX_ZOOM) {
        return
      }
      setOffset({ dx, dy, scale: newScale })
    } else if (scale > 1) {
      const newDy = dy + e.deltaY
      setOffset({ dx, dy: newDy, scale })
    }
  }

  useEffect(() => {
    const elementRef = interactionRef.current

    if (!elementRef) return

    elementRef.style.transform = `translate3d(${dx}px, ${dy}px, 0) scale(${scale})`
    elementRef.style.cursor = `${isDragging ? 'grabbing' : scale > 1 ? 'zoom-out' : 'zoom-in'}`

    elementRef.addEventListener('mousedown', handleMouseDown)
    elementRef.addEventListener('wheel', handleWheel)
    return () => {
      elementRef.removeEventListener('mousedown', handleMouseDown)
      elementRef.removeEventListener('wheel', handleWheel)
    }
  }, [dx, dy, scale, isDragging])

  return { dx, dy, scale, isDragging, setIsDragging, setOffset, interactionRef }
}

import { AttachmentResponse } from '@api/messages/types/attachment-response'
import { AttachmentsProps } from '@modules/message-attachments/attachments'
import { Indicator } from '@modules/message-attachments/indicator'
import {
  AttachmentContainer,
  AttachmentOverlay,
  AttachmentsContainer,
  GridContainer,
} from '@modules/message-attachments/styled'
import { MAX_VISIBLE_ATTACHMENTS } from '@utils/variables'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui'
import { AttachmentFetcher } from '../attachment-fetcher'
import { AttachmentPreview } from '../attachment-preview'

export const Attachments: FC<AttachmentsProps> = ({
  attachments,
  createdAt,
  onClick,
  hasIndicator,
  isIncoming = false,
  isLoading = false,
  ...spacing
}) => {
  const totalAttachments = attachments.length
  const { t } = useTranslation('attachments')

  const shouldShowOverlay = useMemo(
    () => (index: number) => {
      return totalAttachments > MAX_VISIBLE_ATTACHMENTS && index + 1 === MAX_VISIBLE_ATTACHMENTS
    },
    [totalAttachments]
  )

  const attachmentIds = useMemo(() => {
    return attachments.slice(0, MAX_VISIBLE_ATTACHMENTS).map((attachment) => attachment.id!)
  }, [attachments])

  const renderAttachments = (attachments: AttachmentResponse[]) => {
    if (totalAttachments === 1) {
      return renderSingleAttachment(attachments)
    } else {
      return renderMultipleAttachments(attachments)
    }
  }

  const renderSingleAttachment = (attachments: AttachmentResponse[]) => (
    <AttachmentContainer width="32rem" height="32rem" role="button" onClick={() => onClick(0)}>
      <AttachmentPreview type={attachments[0].type} uri={attachments[0].uri || ''} />
    </AttachmentContainer>
  )

  const renderMultipleAttachments = (fetchedAttachments: AttachmentResponse[]) => (
    <GridContainer attachmentsCount={totalAttachments}>
      {fetchedAttachments.map((attachment, index) => (
        <AttachmentContainer key={attachment.id} role="button" onClick={() => onClick(index)}>
          <AttachmentPreview type={attachment.type} uri={attachment.uri || ''} />
          {shouldShowOverlay(index) && (
            <AttachmentOverlay>
              <Text color="white">
                +{totalAttachments - MAX_VISIBLE_ATTACHMENTS} {t('more')}
              </Text>
            </AttachmentOverlay>
          )}
        </AttachmentContainer>
      ))}
    </GridContainer>
  )

  return (
    <AttachmentsContainer isIncoming={isIncoming} hasIndicator={hasIndicator} {...spacing}>
      <AttachmentFetcher attachmentIds={attachmentIds}>
        {(attachments) => renderAttachments(attachments)}
      </AttachmentFetcher>
      {hasIndicator && <Indicator isLoading={isLoading} createdAt={createdAt} />}
    </AttachmentsContainer>
  )
}

import { ListLayout } from '@modules/teammates-layout/list-layout'
import React, { FC } from 'react'
import { ContactsLayoutProps } from './index'

export const ContactsListLayout: FC<ContactsLayoutProps> = ({
  users,
  handleSendClick,
  handleUserClick,
  isUsersFetching,
  setIsTriggerInView,
}) => {
  return (
    <ListLayout
      users={users ?? []}
      handleSendClick={handleSendClick}
      handleUserClick={handleUserClick}
      isUsersFetching={isUsersFetching}
      setIsTriggerInView={setIsTriggerInView}
    />
  )
}

import { useGetMe } from '@api/account/hooks/use-get-me'
import { useBannersContext } from '@contexts/banners-provider'
import { useSkeleton } from '@hooks/use-skeleton'
import { PersonalLanguages } from '@modules/personal-languages'
import { PersonalPreferences } from '@modules/personal-preferences'
import { getFeatureFlag } from '@utils/flags-manager'
import { getContainerHeight } from '@utils/get-container-height'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { PersonalSettingsWithSkeleton } from 'src/features/user/components/personal-settings'
import { Container, Heading, RoundedContainer, Tab, Tabs, TabsVariant, ScrollableTab } from 'ui'

export const Personal = () => {
  const { t } = useTranslation(['personal'])
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()
  const { isLoading: isGeneralDataLoading } = useGetMe()
  const { showSkeleton, hideSkeleton } = useSkeleton()

  useEffect(() => {
    isGeneralDataLoading
      ? showSkeleton(SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1)
      : hideSkeleton(SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1)
  }, [isGeneralDataLoading, showSkeleton, hideSkeleton])

  const tabs = [
    <ScrollableTab title={t('tabGeneralLabel')} key={t('tabGeneralLabel')}>
      <PersonalSettingsWithSkeleton
        componentName={SkeletonComponentNames.SETTINGS_PERSONAL_GENERAL_1}
      />
    </ScrollableTab>,
    <ScrollableTab title={t('tabLanguagesLabel')} key={t('tabLanguagesLabel')}>
      <PersonalLanguages />
    </ScrollableTab>,
  ]

  if (getFeatureFlag('showOnProd')) {
    tabs.push(
      <Tab title={t('tabPreferencesLabel')} key={t('tabPreferencesLabel')}>
        <PersonalPreferences />
      </Tab>
    )
  }

  return (
    <Container
      display="flex"
      flexDirection="column"
      height={getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)}
      p="2rem"
    >
      <RoundedContainer p="2rem 2rem 6rem" flex="0 1 100%" overflow="hidden">
        <Heading mb="0.8rem">{t('pageTitle')}</Heading>
        <Tabs variant={TabsVariant.UNDERLINE}>{tabs}</Tabs>
      </RoundedContainer>
    </Container>
  )
}

import { useBannersContext } from '@contexts/banners-provider'
import { getContainerHeight } from '@utils/get-container-height'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Heading, RoundedContainer } from 'ui'
import { ContactsLayout } from '../features/chats-contacts/components/contacts-layout'

export const UsersNfc = () => {
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  const { t } = useTranslation(['teammates'])

  return (
    <PageContainer
      internetConnectionBannerShown={internetConnectionBannerShown}
      notificationsBannerShown={notificationsBannerShown}
    >
      <RoundedContainer p="2rem 2rem 3.6rem" flex="0 1 100%" overflow="hidden">
        <Heading mb="1.6rem">{t('pageTitle')}</Heading>
        <ContactsLayout />
      </RoundedContainer>
    </PageContainer>
  )
}

const PageContainer = styled(Container)<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  padding: 2rem;
`

import { AttachmentResponse } from '@api/messages/types/attachment-response'
import { Indicator } from '@modules/message-attachments/indicator'
import {
  AttachmentContainer,
  AttachmentOverlay,
  AttachmentsContainer,
  GridContainer,
} from '@modules/message-attachments/styled'
import { MAX_VISIBLE_ATTACHMENTS } from '@utils/variables'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { Text } from 'ui'
import { AttachmentFetcher } from '../attachment-fetcher'

export interface AttachmentsProps extends SpaceProps {
  attachments: AttachmentResponse[]
  onClick: (index: number) => void
  hasIndicator: boolean
  createdAt?: Date
  isIncoming?: boolean
  isLoading?: boolean
}

interface AttachmentsWithFetcherProps extends AttachmentsProps {
  conversationId: string
}

export const Attachments: FC<AttachmentsWithFetcherProps> = ({
  attachments,
  conversationId,
  createdAt,
  onClick,
  hasIndicator,
  isIncoming = false,
  isLoading = false,
  ...spacing
}) => {
  const { t } = useTranslation('attachments')
  const totalAttachments = attachments.length

  const shouldShowOverlay = (index: number) => {
    return totalAttachments > MAX_VISIBLE_ATTACHMENTS && index + 1 === MAX_VISIBLE_ATTACHMENTS
  }

  return (
    <AttachmentsContainer isIncoming={isIncoming} hasIndicator={hasIndicator} {...spacing}>
      {totalAttachments === 1 ? (
        <AttachmentFetcher conversationId={conversationId} {...attachments[0]}>
          {({ src, name }) => {
            return (
              <AttachmentContainer
                width="32rem"
                height="32rem"
                role="button"
                onClick={() => onClick(0)}
              >
                <img src={src} alt={name ?? t('image')} />
              </AttachmentContainer>
            )
          }}
        </AttachmentFetcher>
      ) : (
        <GridContainer attachmentsCount={totalAttachments}>
          {attachments.slice(0, MAX_VISIBLE_ATTACHMENTS).map((attachment, index) => (
            <AttachmentFetcher conversationId={conversationId} key={attachment.id} {...attachment}>
              {({ src, id, name }) => (
                <AttachmentContainer key={id} role="button" onClick={() => onClick(index)}>
                  <img src={src} alt={name ?? t('image')} />
                  {shouldShowOverlay(index) && (
                    <AttachmentOverlay>
                      <Text color="white">
                        +{totalAttachments - MAX_VISIBLE_ATTACHMENTS} {t('more')}
                      </Text>
                    </AttachmentOverlay>
                  )}
                </AttachmentContainer>
              )}
            </AttachmentFetcher>
          ))}
        </GridContainer>
      )}
      {hasIndicator && <Indicator isLoading={isLoading} createdAt={createdAt} />}
    </AttachmentsContainer>
  )
}

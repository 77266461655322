import { AttachmentResponse } from '@api/messages/types/attachment-response'
import { AttachmentFetcher } from '@modules/attachment-fetcher'
import React, { FC, forwardRef, RefAttributes } from 'react'
import styled from 'styled-components'
import { Container } from 'ui'

export interface ImgPreviewProps {
  attachments: AttachmentResponse[]
  onZoom: VoidFunction
  currentIndex: number
  alt: string
}

interface ImgPreviewWithFetcherProps extends ImgPreviewProps {
  conversationId: string
}

export const ImgPreview: FC<ImgPreviewWithFetcherProps & RefAttributes<HTMLDivElement>> =
  forwardRef<HTMLDivElement, ImgPreviewWithFetcherProps>(
    ({ attachments, onZoom, conversationId, currentIndex, alt }, ref) => {
      return (
        <AttachmentFetcher
          key={attachments[currentIndex].id}
          conversationId={conversationId}
          {...attachments[currentIndex]}
        >
          {({ src, id, name }) => (
            <ImgContainer key={id} onMouseUp={onZoom} ref={ref}>
              <img src={src} alt={name ?? alt} />
            </ImgContainer>
          )}
        </AttachmentFetcher>
      )
    }
  )

ImgPreview.displayName = 'ImgPreview'

export const MediaContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
  border-radius: 0.8rem;
  overflow: hidden;
  & img,
  & video {
    width: 100%;
    height: 100%;
    max-height: 80vh;
    object-fit: cover;
  }
`

export const ImgContainer = styled(MediaContainer)`
  cursor: zoom-in;
`

import { cookieStorage } from '@api/auth/oauth/auth-service'
import { User } from 'oidc-client-ts'
import { createContext, useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticator } from '../app'

interface AuthProvideProps {
  children: JSX.Element | JSX.Element[]
}

interface SignInProps {
  redirectUrl?: string
}

export interface AuthStateDefinition {
  user: User | null
  login: ({ redirectUrl }: SignInProps) => void
  logout: () => void
  loginCallback: () => Promise<void>
  refreshToken: () => Promise<void>
}

export const initData: AuthStateDefinition = {
  user: null,
  login: () => {},
  logout: () => {},
  loginCallback: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
  refreshToken: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
}

const AuthContext = createContext<AuthStateDefinition>(initData)

const AuthProvider = ({ children }: AuthProvideProps) => {
  const [user, setUser] = useState<User | null>(
    JSON.parse(
      cookieStorage.getItem(
        `oidc.user:https://identity.${process.env.API_ENV}.nativechats.tech:native-flow`
      ) || 'null'
    ) || undefined
  )
  const navigate = useNavigate()

  const login = async ({ redirectUrl }: SignInProps) => {
    try {
      await authenticator.login(redirectUrl)
      const user = await authenticator.getUser()
      setUser(user)
    } catch (error) {
      console.error(error)
      setUser(null)
    }
  }

  const refreshToken = async () => {
    try {
      const user = await authenticator.refreshToken()
      setUser(user)
    } catch (error) {
      console.error(error)
      setUser(null)
      throw error
    }
  }

  const loginCallback = async () => {
    try {
      const authedUser = await authenticator.loginCallback()
      if (authedUser?.state) {
        navigate(authedUser.state)
      }
      setUser(authedUser)
    } catch (error) {
      console.error(error)
    }
  }

  const logout = async () => {
    sessionStorage.removeItem('workspace.token')
    try {
      await authenticator.logout()
    } catch (error) {
      console.error(error)
    } finally {
      setUser(null)
    }
  }
  return (
    <AuthContext.Provider value={{ user, login, logout, loginCallback, refreshToken }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }

import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { TeammatesControls } from '@modules/teammates-controls'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { routes } from '@routes/chats/routes'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetContacts } from '../../hooks/get-contacts'
import { ContactsListLayout } from './list-layout'
import { ContactsTileLayout } from './tile-layout'

export interface ContactsLayoutProps {
  handleSendClick: (userId: string) => void
  handleUserClick: (userId: string) => void
  isUsersFetching: boolean
  setIsTriggerInView: (c: boolean) => void
  users?: User[]
}

export const ContactsLayout = () => {
  const navigate = useNavigate()
  const { data: users, isFetching: isUsersFetching } = useGetContacts(ConnectionScope._0)
  const { chats } = useGetChatList()
  const { meData } = useGetMe()
  const { data, createChat: createChatMutation, isCreateChatSuccess } = useCreateChat()

  const [isTileLayout, setIsTileLayout] = useState(true)
  const [searchString, setSearchString] = useState('')

  const { setType, setOpen, setSidePageUserId } = useSidePage()

  const filteredUsers =
    users?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`
      return fullName.toLowerCase().includes(searchString.toLowerCase())
    }) ?? []

  //TODO: described here NAT-2880
  const findExistingChat = (userId: string, isPrivate: boolean) => {
    if (isPrivate) {
      return chats.find(
        ({ chatUsers }) => chatUsers.length === 1 && chatUsers[0]?.user.userId === userId
      )
    }

    return chats
      .filter(({ chatUsers }) => chatUsers.length === 2)
      .find(({ chatUsers }) => {
        return chatUsers.some(({ user }) => user.userId === userId)
      })
  }

  const redirectToChat = (chatId: string) => {
    navigate(`/${routes.chats}/${chatId}`, { replace: true })
  }

  const handleSendClick = (userId: string) => {
    const existingChat = findExistingChat(userId, userId === meData?.userId)

    if (existingChat) {
      redirectToChat(existingChat.id)
    } else {
      createChatMutation({ userIds: [userId] })
    }
    redirectToChat
  }
  const handleUserClick = (userId: string) => {
    setOpen(true)
    setType(SidePageTypes.USER)
    setSidePageUserId(userId)
  }

  const setIsTriggerInView = (_c: boolean) => {}

  useEffect(() => {
    if (isCreateChatSuccess && data) {
      redirectToChat(data.id)
    }
  }, [data, isCreateChatSuccess])

  return (
    <>
      <TeammatesControls
        isTileLayout={isTileLayout}
        searchValue={searchString}
        setSearchValue={setSearchString}
        setIsTileLayout={setIsTileLayout}
        mb="1.6rem"
        withoutSort
        sortingDelay={300}
      />
      {isTileLayout ? (
        <ContactsTileLayout
          users={filteredUsers}
          handleSendClick={handleSendClick}
          handleUserClick={handleUserClick}
          isUsersFetching={isUsersFetching}
          setIsTriggerInView={setIsTriggerInView}
        />
      ) : (
        <ContactsListLayout
          users={filteredUsers}
          handleSendClick={handleSendClick}
          handleUserClick={handleUserClick}
          isUsersFetching={isUsersFetching}
          setIsTriggerInView={setIsTriggerInView}
        />
      )}
    </>
  )
}

import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { ChatUser } from '@api/chats/types/chat-user'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { getStatusVariant } from '@utils/get-status'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Text } from 'ui'
import { InnerContainer, MemberListItem } from './styled'

export const ListItem: FC<ChatUser> = ({ user }) => {
  const { firstName, lastName, color, userId, online, avatar, isDeleted } = user
  const { setType, setSidePageUserId } = useSidePage()
  const { t } = useTranslation('user-detail')

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: user.userId,
    //@ts-ignore
    //TODO https://nativechats.atlassian.net/jira/software/c/projects/NAT/boards/44?assignee=638a2f9e77acd224b341ed08&selectedIssue=NAT-1199
    avatarId: user.avatar?.id,
  })

  const userFullName = user?.isDeleted ? t('deactivatedAccount') : getFullName(firstName, lastName)

  return (
    <MemberListItem
      onClick={() => {
        setType(SidePageTypes.USER)
        setSidePageUserId(userId)
      }}
    >
      <InnerContainer>
        <Avatar
          imgUrl={avatar?.mediaUri || usersAvatarUrl}
          name={userFullName}
          isDeleted={isDeleted}
          iconSize={32}
          status={getStatusVariant(online)}
          label={firstName}
          size="4.4rem"
          bgColor={`#${color}`}
          isAvatarExists={enabled}
        />
        <Text as="span" ml="1.2rem">
          {userFullName}
        </Text>
      </InnerContainer>
    </MemberListItem>
  )
}

import React from 'react'
import styled from 'styled-components'
import { Container, StatusBadge, StatusVariant, Text } from 'ui'

export interface StatusLabelProps {
  label: string
  status: StatusVariant
  isPopup: boolean
}

export const StatusLabel: React.FC<StatusLabelProps> = (props) => {
  const { label, status, isPopup } = props

  return (
    <Container display="flex" alignItems="center" width="100%" overflow="hidden">
      <Label isPopup={isPopup} label={label} status={status}>
        {label}
      </Label>
      <Container as="span">
        <StatusBadge status={status} sizeLg={true} />
      </Container>
    </Container>
  )
}

const Label = styled(Text)<StatusLabelProps>`
  font-size: ${(props) => (props.isPopup ? '1.6rem' : '1.3rem')};
  color: ${(props) => (props.isPopup ? props.theme.colors.black : props.theme.colors.greyDark)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

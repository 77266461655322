import { WorkspaceUsers } from '@api/account/hooks/use-get-users'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { QuickActions } from '@modules/quick-actions'
import { TeammateInfo } from '@modules/teammate-card/teammate-info'
import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Avatar, SendIcon, StatusVariant, Text, UserIcon } from 'ui'

export const TeammateCard: FC<WorkspaceUsers> = ({
  userId,
  firstName,
  lastName,
  position,
  joined,
  color,
  handleSendClick,
  handleUserClick,
  avatar,
  online,
}) => {
  const [quickActionsShown, setQuickActionsShown] = useState<boolean>(false)
  const [_isHoveringOverQuickActions, setIsHoveringOverQuickActions] = useState<boolean>(false)

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: userId,
    avatarId: avatar?.id,
  })

  const onMouseEnter = useCallback(() => {
    setQuickActionsShown(true)
  }, [setQuickActionsShown])
  const onMouseLeave = useCallback(() => {
    setQuickActionsShown(false)
  }, [setQuickActionsShown])
  return (
    <Tile onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <AvatarContainer onClick={() => handleUserClick(userId)}>
        <StyledAvatar
          label={firstName[0]?.toUpperCase()}
          bgColor={`#${color}`}
          name={firstName}
          size="100%"
          imgUrl={usersAvatarUrl ?? avatar?.mediaUri}
          fontSize="7.6rem"
          isAvatarExists={enabled}
        />
      </AvatarContainer>
      <TeammateInfo
        firstName={firstName}
        lastName={lastName}
        status={online?.status === 1 ? StatusVariant.ONLINE : StatusVariant.OFFLINE}
        joined={joined}
        position={position}
      />
      {quickActionsShown && (
        <QuickActions
          borderRadius="0.8rem"
          top="0.4rem"
          right="0.4rem"
          height="3.2rem"
          padding="0.4rem 0.8rem"
          actions={[
            {
              actionName: 'send',
              onActionClick: () => {
                handleSendClick(userId)
              },
              actionIcon: <SendIcon />,
            },
            {
              actionName: 'user',
              onActionClick: () => {
                handleUserClick(userId)
              },
              actionIcon: <UserIcon />,
            },
          ]}
          setIsHoveringOverQuickActions={setIsHoveringOverQuickActions}
        />
      )}
    </Tile>
  )
}

const Tile = styled.div`
  position: relative;
  & ${Text} {
    max-width: 14.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const AvatarContainer = styled.div`
  cursor: pointer;
  padding-top: 100%;
  position: relative;
  margin-bottom: 0.8rem;
`

const StyledAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetDevicesResponse } from '../models/GetDevicesResponse';
import type { PersonalProfileResponse } from '../models/PersonalProfileResponse';
import type { SetDeviceDetailRequest } from '../models/SetDeviceDetailRequest';
import type { SetLanguageRequest } from '../models/SetLanguageRequest';
import type { UpdateProfileRequest } from '../models/UpdateProfileRequest';
import type { UploadAvatarRequest } from '../models/UploadAvatarRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * Delete device
     * Remove the device from the device list
     * @param deviceId
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiV1MeDevices(
        deviceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/me/devices/{deviceId}',
            path: {
                'deviceId': deviceId,
            },
        });
    }

    /**
     * Set device detail
     * Set the current device settings to be uses by BE
     * @returns GetDevicesResponse Success
     * @throws ApiError
     */
    public static getApiV1MeDevices(): CancelablePromise<GetDevicesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/me/devices',
        });
    }

    /**
     * Set device detail
     * Set the current device settings to be uses by BE
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiV1MeDevices(
        requestBody?: SetDeviceDetailRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/me/devices',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Get profile
     * Get the profile information of the current user
     * @returns PersonalProfileResponse Success
     * @throws ApiError
     */
    public static getApiV1Me(): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/me',
        });
    }

    /**
     * Update profile
     * Update current user profile
     * @param requestBody
     * @returns PersonalProfileResponse Success
     * @throws ApiError
     */
    public static patchApiV1Me(
        requestBody?: UpdateProfileRequest,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/me',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Remove avatar
     * Remove the avatar of the current user
     * @returns PersonalProfileResponse Success
     * @throws ApiError
     */
    public static deleteApiV1MeAvatar(): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/me/avatar',
        });
    }

    /**
     * Upload avatar
     * Upload current user avatar
     * @param requestBody
     * @returns PersonalProfileResponse Success
     * @throws ApiError
     */
    public static patchApiV1MeAvatar(
        requestBody?: UploadAvatarRequest,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/me/avatar',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * Set language
     * Set current user languages
     * @param requestBody
     * @returns PersonalProfileResponse Success
     * @throws ApiError
     */
    public static postApiV1MeLanguage(
        requestBody?: SetLanguageRequest,
    ): CancelablePromise<PersonalProfileResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/me/language',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageListType, ImageType } from 'react-images-uploading'
import { toast } from 'react-toastify'

type UseAttachments = () => [Array<ImageType>, (list: ImageListType) => void]

export const useAttachments: UseAttachments = () => {
  const [attachments, setAttachments] = useState<ImageListType>([])
  const { t } = useTranslation('attachments')

  const onAttachmentsChange = (imageList: ImageListType) => {
    if (imageList.length > 10) {
      toast.error(`${t('maxFilesError')}`)
    }
    setAttachments(imageList)
  }

  return [attachments, onAttachmentsChange]
}

import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

export const MessageInputSkeleton = () => {
  const theme = useTheme()
  return (
    <Skeleton
      width="100%"
      height={76}
      borderRadius={16}
      baseColor={theme.colors.beigeDark}
      style={{ borderRadius: 16, borderBottomRightRadius: 0 }}
    />
  )
}

import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'
import { getAttachment } from '../get-attachment'

export const ATTACHMENT = 'sending-attachment-fetcher'

export const useGetAttachment = (attachmentId: string, conversationId?: string) => {
  //we are checking conversation id exists with enabled property in useQuery hook options
  return useQuery([ATTACHMENT, attachmentId], () => getAttachment(conversationId!, attachmentId), {
    staleTime: AVATAR_STALE_TIME,
    enabled: Boolean(attachmentId && conversationId),
  })
}

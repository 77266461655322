import { User } from '@api/account/types/user'
import { GeneralMessageProps } from '@components/message'
import { ConversationType } from '@utils/get-chat-type'
import React, { FC, MutableRefObject } from 'react'
import { EmptyConversationBody } from './empty-conversation-body'
import { MessageList } from './message-list'
import { ChatBodyContainer } from './styled'

export interface ChatBodyProps {
  messages: GeneralMessageProps[]
  type: ConversationType
  users: User[]
  setIsInView: (c: boolean) => void
  setTriggerIsInView?: (c: boolean) => void
  messageListRef?: MutableRefObject<HTMLDivElement | null>
  isLoading?: boolean
}

export const ConversationBody: FC<ChatBodyProps> = ({
  messages,
  type,
  users,
  setIsInView,
  messageListRef,
  setTriggerIsInView,
  isLoading,
}) => {
  if (messages.length === 0 && !isLoading) {
    return <EmptyConversationBody type={type} users={users} />
  }

  return (
    <ChatBodyContainer ref={messageListRef} hideScrollbar>
      <MessageList
        isLoading={isLoading}
        setTriggerIsInView={setTriggerIsInView}
        setIsInView={setIsInView}
        messages={messages}
      />
    </ChatBodyContainer>
  )
}

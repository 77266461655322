import { CHANNEL_LIST, CHAT_LIST } from '@api/chats/query-keys'
import { MessageDetail } from '@api/messages/types/message-detail'
import { AppDispatch, store } from '@app/flow/store'
import { QueryClient } from '@tanstack/react-query'
import { notificationsManager } from '@utils/notifications'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { markAsUnread } from 'src/features/chats/slice'
import { receiveMessage } from '../../features/conversation/slice'

// TODO:  After back end changes socket payload to be same DTO as response from getMessageById, we can get rid of this query in socket payload this field is mandatory

export const handleNewMessage =
  (dispatch: AppDispatch, queryClient: QueryClient) => async (message: MessageDetail) => {
    try {
      const state = store.getState()
      const isNewConversation = !state.chats.ids.includes(message.chatId)

      const newMessage = await chatApi.getMessage({ id: message.messageId! })
      dispatch(receiveMessage(newMessage))
      dispatch(markAsUnread({ chatId: message.chatId }))

      if (isNewConversation) {
        queryClient.invalidateQueries([CHANNEL_LIST, CHAT_LIST])
      }

      notificationsManager.tryToSendNotification(message)
    } catch (error) {
      //todo: handle error in sentry
      console.error(error)
    }
  }

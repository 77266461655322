/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetLanguagesResponse } from '../models/GetLanguagesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LanguagesService {

    /**
     * Supported languages for translations
     * List of all supported languages for translation services
     * @param lang
     * @returns GetLanguagesResponse Success
     * @throws ApiError
     */
    public static getV2Languages(
        lang?: string,
    ): CancelablePromise<GetLanguagesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/languages',
            query: {
                'lang': lang,
            },
        });
    }

}

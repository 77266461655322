import { useGetTimezones } from '@api/timezones/hooks/use-get-timezones'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { Container, OptionType, Select, TextField } from 'ui'
import { FlowPersonalValues } from './types'

export const ContentFlow: FC = () => {
  const { t } = useTranslation(['personal'])
  const { errors, values, handleChange, setFieldValue } = useFormikContext<FlowPersonalValues>()
  const { timezones } = useGetTimezones()
  return (
    <>
      <Container display="flex" mb="2.4rem" flexDirection={{ _: 'column', desktop: 'row' }}>
        <TextField
          id="firstName"
          name="firstName"
          label={t('firstName')}
          value={values.firstName}
          placeholder={t('placeholder.firstName')}
          onChange={handleChange}
          warning={Boolean(errors?.firstName)}
          helperText={errors?.firstName}
          width="100%"
        />
        <TextField
          id="lastName"
          name="lastName"
          label={t('lastName')}
          value={values.lastName}
          placeholder={t('placeholder.lastName')}
          onChange={handleChange}
          warning={Boolean(errors?.lastName)}
          helperText={errors?.lastName}
          width="100%"
          m={{ _: '2.4rem 0 0', desktop: '0 0 0 1.6rem' }}
        />
      </Container>
      <Container display="flex" mb="2.4rem" flexDirection={{ _: 'column', desktop: 'row' }}>
        <TextField
          id="email"
          name="email"
          label={t('email')}
          type="email"
          value={values.email}
          onChange={handleChange}
          warning={Boolean(errors?.email)}
          helperText={errors?.email}
          description={t('emailDesc')}
          disabled
          width="100%"
        />
        <Container width="100%" display="flex">
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label={t('phoneNumber')}
            type="text"
            placeholder={t('placeholder.phoneNumber')}
            value={values.phoneNumber}
            onChange={handleChange}
            warning={Boolean(errors?.phoneNumber)}
            helperText={errors?.phoneNumber}
            width="100%"
            m={{ _: '2.4rem 0 0', desktop: '0 0 0 1.6rem' }}
          />
        </Container>
      </Container>
      <Select
        label={t('timezone')}
        options={timezones || []}
        value={{
          value: values.timezone?.id || '',
          label: values.timezone?.displayName || '',
        }}
        onChange={(timezone) =>
          setFieldValue('timezone', {
            id: (timezone as SingleValue<OptionType>)?.value,
            displayName: (timezone as SingleValue<OptionType>)?.label,
          })
        }
        width="100%"
        maxMenuHeight={240}
        mb="2.4rem"
      />
      <TextField
        width="100%"
        id="jobTitle"
        name="jobTitle"
        label={t('jobTitle')}
        placeholder={t('placeholder.jobTitle')}
        type="text"
        value={values.jobTitle}
        onChange={handleChange}
        warning={Boolean(errors?.jobTitle)}
        helperText={errors?.jobTitle}
        mb="2.4rem"
      />
    </>
  )
}

import { InputButton } from '@components/message-input/styled'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachIcon } from 'ui'

export const AttachmentButton: FC = () => {
  const { onImageUpload, lengthError } = useAttachmentsUploadingContext()

  const { t } = useTranslation('attachments')

  return (
    <InputButton onClick={onImageUpload} aria-label={t('uploadAttachments')} disabled={lengthError}>
      <AttachIcon />
    </InputButton>
  )
}

import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetChatDetails } from '@api/chats/hooks/use-get-chat-details'
import { useReadConversation } from '@api/chats/hooks/use-read-conversation'
import { useAppDispatch } from '@app/flow/hooks'
import { MessageInput, MessageInputMode } from '@components/message-input'
import { TypingIndicator } from '@components/typing-indicator'
import {
  AttachmentsUploadingProvider,
  useAttachmentsUploadingContext,
} from '@contexts/attachments-uploading-provider'
import { useBannersContext } from '@contexts/banners-provider'
import { useAttachments } from '@hooks/use-attachments'
import { useConversationAndTrackTyping } from '@hooks/use-conversation-and-track-typing'
import { useConversationId } from '@hooks/use-conversation-id'
import { useSkeleton } from '@hooks/use-skeleton'
import { ChannelDenied } from '@modules/channel-denied'
import { ChatHeaderWithSkeleton } from '@modules/chat-header'
import { ConversationBody } from '@modules/conversation-body'
import { JoinChannelCta } from '@modules/join-channel-cta'
import { MessageInputSkeleton } from '@modules/skeleton/skeleton-variants/message-input'
import { ConversationType } from '@utils/get-chat-type'
import { getContainerHeight } from '@utils/get-container-height'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { Container } from 'ui'
import { markAsRead as markAsReadAction } from '../features/chats/slice'

export const Conversation: FC = () => {
  const [attachments, onAttachmentsChange] = useAttachments()

  return (
    <AttachmentsUploadingProvider value={attachments} onChange={onAttachmentsChange} multiple>
      <ConversationContent />
    </AttachmentsUploadingProvider>
  )
}

export const ConversationContent: FC = () => {
  const conversationId = useConversationId()
  const {
    data: chatDetails,
    isLoading: isChatDetailsLoading,
    isError: isGetChatDetailsError,
  } = useGetChatDetails(conversationId || undefined)

  const { meData, isLoading: isMeDataLoading } = useGetMe()
  const {
    formattedMessages,
    isLoading: isConversationLoading,
    users,
    messageInputProps,
    typingUsers,
    typingChat,
    typingUsersString,
    fetchNextPageMessageList,
    messageListRef,
    scrollDownHandle,
  } = useConversationAndTrackTyping()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const [inView, setIsInView] = useState(false)
  const [triggerInView, setTriggerIsInView] = useState(false)
  const dispatch = useAppDispatch()
  const { markAsRead } = useReadConversation({
    onMutate: (chatId) => dispatch(markAsReadAction({ chatId })),
  })
  const { dragProps } = useAttachmentsUploadingContext()

  const isMeJoined = useMemo(() => {
    if (!meData || !chatDetails) {
      return false
    }
    const foundUser = chatDetails.chatUsers.find((user) => user.user.userId === meData.userId)

    return foundUser ? !foundUser.leftChat : false
  }, [chatDetails, meData])

  const isLoading = isConversationLoading || isChatDetailsLoading

  const isMessageInputLoading = isMeDataLoading || isChatDetailsLoading

  const showJoinChannel = !isMeDataLoading && !isMeJoined && !isChatDetailsLoading && chatDetails

  // Reset trigger is in view when conversation is changing
  useEffect(() => {
    setTriggerIsInView(false)
  }, [conversationId])

  useEffect(() => {
    if (triggerInView) fetchNextPageMessageList()
  }, [triggerInView, fetchNextPageMessageList])

  useEffect(() => {
    const hasUnreadMessages = chatDetails && chatDetails.unreadMessagesCount > 0
    if (inView && hasUnreadMessages && conversationId) {
      markAsRead(conversationId)
    }
  }, [inView, chatDetails?.unreadMessagesCount])

  useEffect(() => {
    if (isLoading) {
      showSkeleton(SkeletonComponentNames.CONVERSATION_BODY_1)
      showSkeleton(SkeletonComponentNames.CHAT_HEADER_1)
    } else {
      hideSkeleton(SkeletonComponentNames.CONVERSATION_BODY_1)
      hideSkeleton(SkeletonComponentNames.CHAT_HEADER_1)
    }
  }, [hideSkeleton, isLoading, showSkeleton])

  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()

  if (isGetChatDetailsError || chatDetails?.isDeleted) {
    return <ChannelDenied />
  }

  return (
    <Container position="relative" {...dragProps}>
      <PageContainer
        internetConnectionBannerShown={internetConnectionBannerShown}
        notificationsBannerShown={notificationsBannerShown}
      >
        <ChatHeaderWithSkeleton mb="2.4rem" componentName={SkeletonComponentNames.CHAT_HEADER_1} />
        <ConversationBody
          setTriggerIsInView={setTriggerIsInView}
          setIsInView={setIsInView}
          isLoading={isLoading}
          messages={formattedMessages}
          type={ConversationType.PUBLIC_CHANNEL}
          messageListRef={messageListRef}
          users={users.map(({ user }) => user)}
        />
        <Container pt="3.2rem" position="relative">
          {typingUsers.length > 0 && typingChat === conversationId && (
            <TypingIndicator
              text={typingUsersString}
              position="absolute"
              top="0"
              p="0.6rem 0.8rem 0.6rem 4.4rem"
            />
          )}
          {isMessageInputLoading ? (
            <MessageInputSkeleton />
          ) : showJoinChannel ? (
            <JoinChannelCta channel={chatDetails} />
          ) : (
            <MessageInput
              {...messageInputProps}
              inView={inView}
              scrollDownHandle={scrollDownHandle}
              messageInputMode={MessageInputMode.SEND}
              conversationId={conversationId || ''}
            />
          )}
        </Container>
      </PageContainer>
    </Container>
  )
}

const PageContainer = styled(Container)<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  padding: 2rem;
  justify-content: space-between;
`

import { getFeatureFlag } from '@utils/flags-manager'
import React, { FC, ReactNode } from 'react'
import { Container, PopperButton, PopperAction } from 'ui'
import { FreeTrialNotice } from './free-trial-notice'
import { ListItem, PopperContainer } from './styled'

export interface SidebarPopperProps {
  onClose: VoidFunction
  items: SidebarPopperAction[]
  headingComponent?: ReactNode
}

export interface SidebarPopperAction extends PopperAction {
  group?: string
}

export interface ListItemsProps {
  isFirstInGroup?: boolean
  isLastInGroup?: boolean
}

export const SidebarPopper: FC<SidebarPopperProps> = ({ onClose, items, headingComponent }) => {
  const freeTrialLeftDays = 86

  return (
    <PopperContainer pt={headingComponent ? ' 0.8rem' : undefined}>
      {headingComponent}
      {getFeatureFlag('showOnProd') && <FreeTrialNotice freeTrialLeftDays={freeTrialLeftDays} />}
      <Container>
        <ul>
          {items.map((item, index, array) => {
            const isFirstInGroup = index === 0 || item.group !== array[index - 1].group
            const isLastInGroup =
              index === array.length - 1 || item.group !== array[index + 1].group
            return (
              <ListItem isFirstInGroup={isFirstInGroup} isLastInGroup={isLastInGroup} key={index}>
                {/* TODO: Refactor to chose between button and link (to redirect our of the app, in new tab) */}
                <PopperButton
                  icon={item.icon}
                  onClick={() => {
                    item.onClick()
                    onClose()
                  }}
                  name={item.name}
                />
              </ListItem>
            )
          })}
        </ul>
      </Container>
    </PopperContainer>
  )
}

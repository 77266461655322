import { accountApi } from './instance'
import { User } from './types/user'

export interface GetUsersResponse {
  users: User[]
  pages: number
  totalTeammatesCount: number
  currentPage: number
}

export async function getUsers(
  sortBy: string = 'name',
  sortDesc: boolean = false,
  pageNumber: number
) {
  const { data } = await accountApi.get<GetUsersResponse>({
    url: '/users',
    sortBy,
    sortDesc,
    pageNumber,
  })
  return data
}

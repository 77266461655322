import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { LOCALES, Language } from '@utils/locales'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { FC } from 'react'
import { SpinnerLightAnimation, Text } from 'ui'
import { IndicatorContainer } from './styled'

interface IndicatorProps {
  isLoading?: boolean
  createdAt?: Date
}

export const Indicator: FC<IndicatorProps> = ({ isLoading, createdAt }) => {
  const { interfaceLanguage } = useInterfaceLanguage()

  return (
    <IndicatorContainer>
      {isLoading ? (
        <SpinnerLightAnimation size={16} />
      ) : (
        createdAt && (
          <Text color="white" variant="smallTextRegular">
            {format(createdAt, 'p', {
              locale: LOCALES[interfaceLanguage.value as Language] || enUS,
            })}
          </Text>
        )
      )}
    </IndicatorContainer>
  )
}

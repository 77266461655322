import { User } from '@api/account/types/user'
import { EmptyChannelMembers } from '@modules/empty-channel-members'
import { EmptyChannelMessages } from '@modules/empty-channel-messages'
import { EmptyChatMessages } from '@modules/empty-chat-messages'
import { ConversationType, CHANNEL_TYPES } from '@utils/get-chat-type'
import React from 'react'
import { FC } from 'react'
import { ScrollContainer } from 'ui'

interface EmptyConversationBodyProps {
  users: User[]
  type: ConversationType
}

export const EmptyConversationBody: FC<EmptyConversationBodyProps> = ({ type, users }) => {
  const renderEmptyContent = (users: User[], type: ConversationType) => {
    // Chat with members and 0 messages
    if (type === ConversationType.CHAT && users.length > 0) {
      return <EmptyChatMessages users={users} mt="auto" />
    }
    // Channels with members and 0 messages
    if (CHANNEL_TYPES.includes(type)) {
      if (users.length > 1) {
        return <EmptyChannelMessages mt="auto" />
      }
      // Channels with no members (except me) and 0 messages
      return <EmptyChannelMembers m="auto" maxWidth="44rem" />
    }

    return null
  }

  return (
    <ScrollContainer display="flex" hideScrollbar>
      {renderEmptyContent(users, type)}
    </ScrollContainer>
  )
}

import { Popover } from '@headlessui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Text, Container, HiddenLabel, PopperList, PopperAction, ArrowIndicator } from 'ui'
import { ChevronContainer, DropdownButton } from './styled'

interface DropdownOptionArgs {
  label: string
  value: string
}

interface PickerDropdownProps extends SpaceProps {
  onChange: (value: any) => void
  value: string
  options: DropdownOptionArgs[]
  label?: string
  preFix?: JSX.Element
  containerProps?: LayoutProps
}

export const PickerDropdown: FC<PickerDropdownProps> = ({
  options,
  preFix,
  value,
  onChange,
  label,
  containerProps,
  ...spaceProps
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['sorting'])

  // map options object to popperOptions which has type of PopperAction
  const popperOptions: PopperAction[] = options.map(({ label, value }) => {
    return {
      name: label,
      onClick: () => {
        onChange(value)
      },
    }
  })

  return (
    <Container {...containerProps}>
      {label && (
        <Text variant="smallTextRegular" mb="0.8rem" color={theme.colors.greyDark}>
          {label}
        </Text>
      )}
      <Popover style={{ position: 'relative' }}>
        {({ open }) => (
          <>
            <HiddenLabel>{t('sortingButton')}</HiddenLabel>
            <Popover.Button style={{ outline: 'none' }}>
              <DropdownButton isOpen={open} {...spaceProps}>
                {preFix && preFix}
                <Text ml="0.4rem">
                  {options.find(({ value: optionValue }) => optionValue === value)?.label}
                </Text>

                <ChevronContainer>
                  <ArrowIndicator open={open} />
                </ChevronContainer>
              </DropdownButton>
            </Popover.Button>
            <Popover.Panel
              style={{ position: 'absolute', zIndex: 10, marginTop: '0.2rem', right: '0' }}
            >
              <PopperList actions={popperOptions} />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </Container>
  )
}

import { useBannersContext } from '@contexts/banners-provider'
import { getContainerHeight } from '@utils/get-container-height'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Heading, ImgContainer } from 'ui'

const IMG_SRC = '/img/group.svg'

export const Welcome = () => {
  const { internetConnectionBannerShown, notificationsBannerShown } = useBannersContext()
  const { t } = useTranslation(['common'])

  return (
    <PageContainer
      internetConnectionBannerShown={internetConnectionBannerShown}
      notificationsBannerShown={notificationsBannerShown}
    >
      <ImgContainer maxWidth="55rem">
        <img src={IMG_SRC} alt={t('imgAlt.welcome')} width={550} height={304} />
      </ImgContainer>
      <Heading variant="h2" as="h1" mt="3.2rem">
        {t('welcomeToNative')}
      </Heading>
    </PageContainer>
  )
}

const PageContainer = styled(Container)<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
`

import { getContainerHeight } from '@utils/get-container-height'
import styled from 'styled-components'

export const MainGrid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: 'sidebar mainscreen sidepage';
  grid-template-columns: 28rem 1fr auto;
  overflow: hidden;
  flex: 1 1 auto;
`

export const MainScreen = styled.div`
  grid-area: mainscreen;
`

export const SidebarContainer = styled.div`
  grid-area: sidebar;
  border-radius: 0 1.6rem 1.6rem 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  &::after {
    content: '';
    z-index: 3;
    position: absolute;
    background: linear-gradient(0deg, rgba(254, 254, 254, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    pointer-events: none;
    width: 28rem;
    border-radius: 0 1.6rem 1.6rem 0;
  }
`

export const StyledContainer = styled.div<{
  internetConnectionBannerShown: boolean
  notificationsBannerShown: boolean
}>`
  display: flex;
  flex-direction: column;
  height: ${({ internetConnectionBannerShown, notificationsBannerShown }) =>
    getContainerHeight(internetConnectionBannerShown, notificationsBannerShown)};
  padding-top: 3rem;
`

import { User } from '@api/account/types/user'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { ContactsRepository, ContactsRepositoryImpl } from './contacts-repository'

export class ContactsService {
  contactsRepository: ContactsRepository

  constructor(contactsRepository: ContactsRepository) {
    this.contactsRepository = contactsRepository
  }

  getContacts = async (scope: ConnectionScope): Promise<User[]> => {
    return this.contactsRepository.getContacts(scope)
  }

  getContact = async (userId: string): Promise<User> => {
    return this.contactsRepository.getContact(userId)
  }
}

export const contactsService = new ContactsService(new ContactsRepositoryImpl())

import { NetworkMode } from '@api/enums'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getUsers, GetUsersResponse } from '../get-users'
import { User } from '../types/user'

export const USERS = 'users'
export const USER_LIST = 'usersList'

interface GetUsersArgs {
  sortBy?: string
  sortDesc?: boolean
  networkMode?: NetworkMode
}

const useGetUsers = ({ sortBy, sortDesc, networkMode }: GetUsersArgs = {}) => {
  const {
    data,
    isLoading: isUsersFetching,
    isError: isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<GetUsersResponse, Error>({
    queryKey: [USERS, USER_LIST],
    queryFn: ({ pageParam }) => getUsers(sortBy, sortDesc, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.currentPage < lastPage.pages) {
        return lastPage.currentPage + 1
      }
      return
    },
    refetchOnWindowFocus: false,
    networkMode: networkMode || NetworkMode.ONLINE,
  })

  const usersData = (data?.pages || []).flatMap(({ users }) => users)
  const users: User[] =
    usersData.map((user) => ({
      ...user,
      status: user.online.status,
      //we need to leave this ternary, because all users registered before fix (non-prod) have null in joiningDate
      joined: user.joiningDate ? new Date(user.joiningDate) : new Date(),
      position: user.jobTitle,
    })) || []

  return {
    users,
    isUsersFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
  }
}

export type WorkspaceUsers = typeof useGetUsers.prototype.users

export { useGetUsers }

import { CreateChatsProps } from '@api/chats/create-chat'
import { CHANNEL_LIST } from '@api/chats/query-keys'
import { Chat } from '@api/chats/types/chat'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { chatApi } from 'src/features/chats/repositories/chat-service'

export const useCreateChannel = () => {
  const queryClient = useQueryClient()

  const {
    data,
    mutate: createChannel,
    ...other
  } = useMutation<Chat, Error, CreateChatsProps>(
    ({ chatName, description, conversationType, userIds }) =>
      chatApi.createChat({
        chatName,
        conversationType,
        description,
        userIds,
      }),
    {
      onSettled: () => {
        void queryClient.invalidateQueries([CHANNEL_LIST])
      },
    }
  )

  return { data, createChannel, ...other }
}

import styled, { keyframes } from 'styled-components'
import { space } from 'styled-system'
import { Button, Container } from 'ui'
import { ExpandingInputProps, InputWrapperProps, MessageInputMode } from '.'

const expandAnimation = keyframes`
  from {
    height: 0;
  }
  to {
    height: 2.4rem;
  }`

export const InputWrapper = styled.div<InputWrapperProps>`
  border-radius: 1.6rem 1.6rem 0rem 1.6rem;
  min-height: 7.6rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
  transition: border-color ${({ theme }) => theme.transitionTimes.short};
  border-color: ${({ theme, isFocused }) => (isFocused ? theme.colors.primary : theme.colors.grey)};
`

export const InputScrollContainer = styled(Container)<ExpandingInputProps>`
  width: 100%;
  overflow-y: auto;
  max-height: ${({ messageInputMode }) =>
    messageInputMode === MessageInputMode.EDIT ? '27rem' : 'calc(50vh - 4.4rem)'};

  // Scrollbar
  &::-webkit-scrollbar {
    width: 1.8rem;
  }
  &::-webkit-scrollbar-thumb {
    border: 0.6rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.grey};
  }
`

export const InputBottom = styled(Container)`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: space-between;
  padding: 0.4rem 1.6rem 0.8rem;
`

export const EditActions = styled(Container)`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`

export const ExpandingInput = styled.div`
  display: block;
  overflow-y: auto;
  font-size: 1.5rem;
  padding: 0.8rem 1.6rem 0rem 1.6rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 1.46;
  outline: none;
  white-space: pre-wrap;
  letter-spacing: -0.017em;
  :empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: ${({ theme }) => theme.colors.greyDark};
  }
  & div {
    animation: ${expandAnimation} ${({ theme }) => theme.transitionTimes.short};
  }

  // Scrollbar
  &::-webkit-scrollbar {
    width: 1.8rem;
  }
  &::-webkit-scrollbar-thumb {
    border: 0.6rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 2rem;
    background-color: ${(props) => props.theme.colors.grey};
  }
  ${space}
`

export const InputButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

export const InputButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 2.4rem;
  color: ${({ theme }) => theme.colors.greyDark};
  transition: color ${({ theme }) => theme.transitionTimes.short};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.greyDark};
    cursor: default;
  }
`

export const SendButton = styled(Button)`
  & svg {
    margin-right: -0.2rem;
  }
`

import { MediaType } from '@api/messages/types/attachment-response'
import { ImgContainer, ImgPreviewProps, MediaContainer } from '@modules/modals/gallery/img-preview'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentFetcher } from '../attachment-fetcher'

export const ImgPreview = forwardRef<HTMLDivElement, ImgPreviewProps>(
  ({ attachments, onZoom, currentIndex }, ref) => {
    const currentAttachment = attachments[currentIndex]

    const { t } = useTranslation('attachments')
    if (!currentAttachment) return null

    return (
      <AttachmentFetcher key={currentAttachment.id} attachmentIds={[currentAttachment.id!]}>
        {(fetchedAttachments) => {
          const attachment = fetchedAttachments[0]

          if (!attachment) return null
          const { uri, type } = attachment

          return type === MediaType.IMG ? (
            <div onMouseUp={onZoom} ref={ref}>
              <ImgContainer>
                <img src={uri!} alt={t('image')} />
              </ImgContainer>
            </div>
          ) : (
            <MediaContainer>
              <video src={uri!} controls autoPlay />
            </MediaContainer>
          )
        }}
      </AttachmentFetcher>
    )
  }
)

ImgPreview.displayName = 'ImgPreviewNcf'

import { useGetAttachment } from '@api/chats/hooks/use-get-attachments'
import { AttachmentResponse } from '@api/messages/types/attachment-response'
import { FC, ReactElement } from 'react'

interface AttachmentFetcherProps extends AttachmentResponse {
  children: (options: AttachmentResponse & { src: string }) => ReactElement
  conversationId: string
}
export const AttachmentFetcher: FC<AttachmentFetcherProps> = ({
  children,
  conversationId,
  ...attachment
}) => {
  const { data: src } = useGetAttachment(attachment.id!, conversationId)

  if (!src) {
    return null
  }

  return children({ src, ...attachment })
}

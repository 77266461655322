import { getUser } from '@api/account/get-user'
import { useQuery } from '@tanstack/react-query'
import { contactsService } from '../../../features/chats-contacts/repository/service'
import { USERS } from './use-get-users'

export const USER_DETAIL = 'userDetail'

export const useGetUser = (id: string | null) => {
  const { data, error } = useQuery(
    [USERS, USER_DETAIL, id],
    () => {
      const app = process.env.APP_ENV
      if (app === 'chats') {
        return contactsService.getContact(id!)
      }
      return getUser(id!)
    }, //will not be executed if id is null
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      staleTime: 10000,
    }
  )
  return { user: data, error }
}

import { ChipInputItem, ChipInputItemProps } from '@components/chip-input/chip-input-item'
import React, { FC } from 'react'
import {
  components,
  MultiValueRemoveProps,
  OptionProps,
  MultiValueGenericProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import ReactSelect from 'react-select'
import { useTheme } from 'styled-components'
import { CloseIcon } from 'ui'
import { useTranslation } from 'react-i18next'

interface ChipInputProps {
  options: ChipInputItemProps[]
  isMulti: boolean
  defaultValue: ChipInputItemProps | null
  setOptionsList: React.Dispatch<React.SetStateAction<any[]>> // TODO: research how to type React-select
}

export const ChipInput: FC<ChipInputProps> = ({
  options,
  isMulti,
  defaultValue,
  setOptionsList,
}) => {
  const appTheme = useTheme()
  const { t } = useTranslation('create-new')

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...props}>
        <CloseIcon />
      </components.MultiValueRemove>
    )
  }

  const MultiValueLabel = (props: MultiValueGenericProps<ChipInputItemProps>) => {
    const { data } = props
    return (
      <components.MultiValueLabel {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          avatarId={data.avatar.id}
          avatarUri={data.avatarUri}
        />
      </components.MultiValueLabel>
    )
  }

  const SingleValue = (props: SingleValueProps<ChipInputItemProps>) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.SingleValue {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          //@ts-ignore
          avatarId={data.avatar?.id}
          avatarUri={data.avatarUri}
        />
      </components.SingleValue>
    )
  }

  const Option = (props: OptionProps<ChipInputItemProps>) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.Option {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          //@ts-ignore
          avatarId={data.avatar?.id}
          avatarUri={data.avatarUri}
        />
      </components.Option>
    )
  }

  const handleOnChange = (options: any) => {
    if (options) {
      setOptionsList(options)
    }
  }

  return (
    <ReactSelect
      // setting key to default value to reset the component when the default value changes
      key={defaultValue ? `${defaultValue}-chip-input` : 'chip-input'}
      options={options}
      isMulti={isMulti}
      isSearchable
      isClearable={false}
      components={{
        DropdownIndicator: undefined,
        IndicatorSeparator: undefined,
        MultiValueRemove,
        MultiValueLabel,
        Option,
        SingleValue,
      }}
      defaultValue={defaultValue ?? []}
      onChange={handleOnChange}
      placeholder={t('toNameOrSurname')}
      styles={multiSelectStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: appTheme.colors.primary,
          neutral10: appTheme.colors.greyLight,
          neutral20: appTheme.colors.grey,
          neutral30: appTheme.colors.greyDark,
        },
      })}
    />
  )
}

const multiSelectStyles: StylesConfig<ChipInputItemProps, boolean> = {
  control: (base, props) => ({
    ...base,
    'minHeight': '4.8rem',
    'alignItems': 'center',
    'boxShadow': 'none',
    'backgroundColor': props.theme.colors.neutral10,
    'borderColor': props.menuIsOpen ? props.theme.colors.primary : props.theme.colors.neutral20,
    'borderRadius': '0.8rem',
    'overflowY': 'auto',
    'cursor': 'pointer',
    ':hover': {
      borderColor: props.menuIsOpen ? props.theme.colors.primary : props.theme.colors.neutral20,
    },
  }),
  multiValue: (base, props) => ({
    ...base,
    backgroundColor: props.theme.colors.neutral0,
    borderRadius: '0.8rem',
    margin: `0.4rem 0.8rem 0.4rem 0`,
    padding: '0.4rem 1.2rem',
    height: '3.2rem',
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueLabel: (base) => ({
    ...base,
    paddingLeft: 0,
    fontSize: '1.5rem',
  }),
  multiValueRemove: (base) => ({
    ...base,
    'padding': 0,
    'flex': 0,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  }),
  placeholder: (base, props) => ({
    ...base,
    color: props.theme.colors.neutral30,
    margin: 'auto 0',
  }),
  container: (base) => ({
    ...base,
    fontSize: '1.5rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.4rem 1.2rem',
  }),
  option: (base, props) => ({
    ...base,
    'padding': '0.8rem 1.2rem',
    'fontSize': '1.5rem',
    'letterSpacing': '-0.017em',
    'display': 'flex',
    'alignItems': 'center',
    'height': '4rem',
    'backgroundColor': props.isFocused ? props.theme.colors.neutral10 : 'transparent',
    'color': '#373234',
    ':not(:last-child)': {
      borderBottom: `1px solid ${props.theme.colors.neutral10}`,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: props.theme.colors.neutral10,
    },
  }),
  menu: (base, props) => ({
    ...base,
    border: `1px solid ${props.theme.colors.neutral20}`,
    borderRadius: '0.8rem',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
}

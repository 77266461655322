import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { ChatUser } from '@api/chats/types/chat-user'
import { useSidePage } from '@hooks/use-side-page'
import { SidePageTypes } from '@layouts/main-layout/side-page'
import { getStatusVariant } from '@utils/get-status'
import { getFullName } from '@utils/helpers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Text } from 'ui'
import { MemberTileItem } from './styled'

export const TileItem: FC<ChatUser> = ({ user }) => {
  const { firstName, lastName, avatar, color, userId, online, isDeleted } = user
  const { t } = useTranslation('user-detail')

  const userFullName = user?.isDeleted ? t('deactivatedAccount') : getFullName(firstName, lastName)

  const { setType, setSidePageUserId } = useSidePage()

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: userId,
    //@ts-ignore
    //TODO https://nativechats.atlassian.net/jira/software/c/projects/NAT/boards/44?assignee=638a2f9e77acd224b341ed08&selectedIssue=NAT-1199
    avatarId: avatar?.id,
  })

  return (
    <MemberTileItem
      onClick={() => {
        setType(SidePageTypes.USER)
        setSidePageUserId(userId)
      }}
    >
      <Avatar
        imgUrl={avatar?.mediaUri || usersAvatarUrl}
        name={userFullName}
        label={firstName}
        isDeleted={isDeleted}
        iconSize={32}
        size="9rem"
        fontSize="3rem"
        status={getStatusVariant(online)}
        bgColor={`#${color}`}
        borderRadius="1.2rem"
        isAvatarExists={enabled}
      />
      <Text as="span" variant="textMedium" mt="0.8rem">
        {userFullName}
      </Text>
    </MemberTileItem>
  )
}

import { Float, FloatProps } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { SpaceProps } from 'styled-system'
import { CustomEmojiPickerWrapper } from './styled'

interface EmojiPopoverProps extends SpaceProps {
  onEmojiClick: (clickData: EmojiClickData, event: MouseEvent, onClose?: VoidFunction) => void
  floatProps?: Partial<FloatProps>
  children: ReactNode
}

export const EmojiPopover: FC<EmojiPopoverProps> = ({
  onEmojiClick,
  floatProps,
  children,
  ...spaceProps
}) => {
  const { t } = useTranslation('modal-select-language')

  return (
    <Popover>
      {({ open, close }) => (
        <Float show={open} flip {...floatProps}>
          <Popover.Button style={{ outline: 'none' }}>{children}</Popover.Button>
          <Popover.Panel>
            <CustomEmojiPickerWrapper {...spaceProps}>
              <EmojiPicker
                height={363}
                width={320}
                autoFocusSearch={false}
                onEmojiClick={(emoji, event) => onEmojiClick(emoji, event, close)}
                lazyLoadEmojis
                searchPlaceHolder={t('search')}
              />
            </CustomEmojiPickerWrapper>
          </Popover.Panel>
        </Float>
      )}
    </Popover>
  )
}

import { PersonalProfile } from '@api/account/types/personal-profile'
import { User } from '@api/account/types/user'
import { ChatUser } from '@api/chats/types/chat-user'

const getRecipientOnlineStatus = (
  chatUsers: ChatUser[],
  workspaceUsers: User[],
  me: PersonalProfile | null
) => {
  if (!chatUsers || !workspaceUsers || !me) return 0

  // returns proper online status for chat with 'me' and for personal chat header
  if (chatUsers.length === 1 && chatUsers[0].user.userId === me.userId) return me?.online.status

  // defines proper recipient id for 1 to 1 chats and for chat header
  const recipientId = chatUsers?.find(({user: {userId}}) => userId !== me.userId)?.user.userId
  const recipientUser = workspaceUsers?.find((user) => user.userId === recipientId)

  return recipientUser?.online.status || 0
}

export { getRecipientOnlineStatus }

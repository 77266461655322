const KNOWLEDGE_BASE_LINK = 'https://intercom.help/native1/en/'
const TERMS_LINK = 'https://native.tech/terms/'
const PRIVACY_POLICY_LINK = 'https://native.tech/privacy/'
const MAX_VISIBLE_ATTACHMENTS = 5
const IMG_ZOOMED_SCALE = 2

export {
  KNOWLEDGE_BASE_LINK,
  TERMS_LINK,
  PRIVACY_POLICY_LINK,
  MAX_VISIBLE_ATTACHMENTS,
  IMG_ZOOMED_SCALE,
}
